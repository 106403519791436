<script setup lang="ts">
import { addCustomMsgCallBack, start as janus, JanusIns, LocalEvType } from 'janus-websdk';
import { markRaw, reactive, watch } from 'vue';

const initBody = {
  bussAppId: 98,
  rtcToken: "linjing@2023",
  rtcAppId: 1,
  // channel: 1010, rtcChannelId: "1010", liveId: 1010,
  // rtcUid: 10, connType: 0,
  rtcUid: 65111004,
  rtcChannelId: '4001',
  channel: 4001,
  liveId: 4001,
  connType: 0,
  rtcRole: 1,
  rtmRole: 1,
  // "rtcToken": "054a15b4fac5d0fd0a7c6c63a88d0ac36662a22b"
}
// {"seq":"11","cmd":"swapstartxrlive","response":{"code":200,"codeMsg":"Success","data":{"liveId":405634876696432640,
// "channel":75590,"rtcAppId":2,"rtcUid":10000114,"rtcChannelId":"75590","rtcToken":"a47d8fe9559163f3413f984d0a1ce9db665590ea"}}}
type InitBody = {
  "bussAppId": 103,//
  "rtcToken": "linjing@2023",
  "rtcAppId": 2,
  "connType": 0,//
  "rtcUid": 65111004,
  "rtcChannelId": "4001",
  "channel": 4001,
  "liveId": 4001,
  "peerUid": 35002004
}
const random = Date.now().toString().slice(-4)
localStorage.time ||= random

const state = reactive({
  rtcUid: random + localStorage.time.split('').reverse().join(''),
  rtcChannelId: localStorage.time,
  inputMsg: '',
  canShowMsgs: true,
  canShowLoginLog: false,
  loginLog: [] as { time: string, info: string }[],
  msgs: [] as { uid: number | '我', msg: string, time: string }[],
  linkState: 0 as 0 | 1,//断开,链接/连接中
  remoteVideoStreams: {} as Record<string, MediaStream>,
  videoStreamsMeta: {} as Record<string, InitBody & JanusIns>,
  remoteVideosDisable: {} as Record<string, boolean>,
  remoteAudiosDisable: {} as Record<string, boolean>,
  remoteUids: markRaw({}) as Record<string, string[] | undefined>,
  localStream: { audio: true, video: true },
  role: true
})
const remoteAudioStreams = {} as Record<string, MediaStream>
const audioStreamsMeta = {} as Record<string, InitBody & JanusIns>
addCustomMsgCallBack(data => {
  if (!data) return console.error('meid没得data', data)
  if ('ArrayBuffer' === data.constructor.name) {
    // console.log(data, data.byteLength)
    data = (new TextDecoder().decode(data))
  }
  if (data.evType === LocalEvType.AUDIO_COMIN) onAudio(data)
  console.error('msg', new Date().toLocaleString(), data)
})



const onAudio = ({ stream, id, initBody, janusIns, isLocal }) => {
  const { peerUid } = initBody
  if (isLocal) return console.error('{islocal}')
  state.remoteUids[peerUid] ||= []
  state.remoteUids[peerUid]!.push(id)
  if (remoteAudioStreams[id]) {
    document.getElementById(id)?.remove()
    console.warn('已经存在了Audio')
  }
  remoteAudioStreams[id] = stream
  audioStreamsMeta[id] = Object.assign(janusIns, initBody) as any

  const audioDom = document.createElement('audio')
  audioDom.id = id
  audioDom.srcObject = stream
  audioDom.autoplay = true
  audioDom.muted = true
  audioDom.style.visibility = 'hidden'
  audioDom.onplay = () => {
    console.error('play')
    audioDom.muted = false
  }
  document.body.appendChild(audioDom)
}

watch(() => state.rtcChannelId, () => localStorage.time = state.rtcChannelId)

let janusIns: ReturnType<typeof janus>

const start = () => {
  let { rtcUid, rtcChannelId, role } = state
  console.error({ role })
  const _role = role ? 1 : 0
  if (!rtcChannelId || !rtcUid) return console.error('参数不完整')
  state.linkState = 1
  Object.assign(initBody, {
    rtcUid: +rtcUid,
    rtcChannelId,
    channel: +rtcChannelId,
    liveId: +rtcChannelId,
    rtcRole: _role,
    rtmRole: _role,
  })
  console.log('初始加入参数', initBody)
  janusIns = janus({
    initBody,
    localVideoContainer: document.getElementById('local'),
    remoteVideoContainer: document.getElementById('remote'),
    server: 'https://ycrt.yunbzw.com/facertc/linjing',
    localAudioTrackOption: { capture: true },
    localVideoTrackOption: { capture: false }
  })
  janusIns.whenChannelOpened.then(() => updateLoginlog())
}

const sendMsg = () => {
  // {"evType":5,"uid": 19, "msg": "gogogo"}
  const body = { evType: 7, data: state.inputMsg }
  state.msgs.push({ uid: '我', msg: state.inputMsg, time: new Date().toLocaleTimeString() })
  // state.inputMsg = ''
  janusIns.sendText(JSON.stringify(body))
}

const updateLoginlog = () => {
  const entries = Object.entries(localStorage).filter(it => it[0].startsWith('id_'))
  state.loginLog = entries.map(([key, info]) => [+key.split('_')[1], info]).sort((b, a) => a[0] - b[0]).map(([key, info]) => ({ info, time: new Date(key).toLocaleString() }))

}

const toggleLoginlog = () => {
  state.canShowLoginLog = !state.canShowLoginLog
  if (!state.canShowLoginLog) return
  updateLoginlog()
}

</script>

<template>
  <div class="row">
    <div class="col">
      <div class="mb-3">
        <label class="form-label">Uid</label>
        <input v-model="state.rtcUid" type="email" class="form-control" placeholder="Uid">
      </div>
    </div>
    <div class="col">
      <div class="mb-3">
        <label class="form-label">ChannelId</label>
        <input v-model="state.rtcChannelId" type="email" class="form-control" placeholder="ChannelId">
      </div>
    </div>
    <div class="col">
      <div class="mb-1">
        <!-- <label class="form-label">Role</label>
        <input v-model="state.role" type="checkbox" class="btn-check" placeholder="ChannelId"> -->
        <!-- <div class="form-check"> -->
        <input v-model="state.role" class="form-check-input" type="checkbox" id="role">
        <label class="form-check-label" for="role">
          Role
        </label>
        <!-- </div> -->
      </div>
    </div>
    <div class="col btns">
      <div class="btn-group" role="group" aria-label="Basic example">
        <button @click="start" class="btn btn-primary mb-3" :disabled="state.linkState !== 0">加入频道</button>
        <button @click="() => {
          state.linkState = 0
          janusIns.stop()
          state.remoteUids = markRaw({})
        }" class="btn btn-primary mb-3" :disabled="state.linkState !== 1">退出频道</button>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="input-group" style="max-width: 500px;">
      <textarea type="text" class="form-control" style="height: 100px;" v-model="state.inputMsg"
        placeholder="Msg"></textarea>
      <button class="btn btn-primary " :disabled="!state.inputMsg || (state.linkState !== 1)" type="button"
        @click="sendMsg">发送</button>
    </div>
  </div>

  <div class="cards" v-show="state.linkState">
    <div class="card z-2" id="remote">
    </div>
  </div>

  <div class="msgs card" v-if="state.msgs.length && state.canShowMsgs">
    <div class="msg" v-for=" (item, idx) in state.msgs" :key="idx + '' + item.uid">
      <div class="header">
        <cite class="small">{{ item.uid }}</cite>
        <cite class="small">{{ item.time }}</cite>
      </div>
      <div class="body" style="text-align: left;">{{ item.msg }} </div>
    </div>
  </div>
  <button v-if="state.msgs.length" class="btn btn-sm btn-primary btn--close " type="button"
    @click="state.canShowMsgs = !state.canShowMsgs">{{ state.canShowMsgs ? 'off' : 'on' }}</button>

  <div class="msgs card loginlog" v-if="state.loginLog.length && state.canShowLoginLog">
    <div class="msg" v-for=" (item, idx) in state.loginLog" :key="idx + '' + JSON.stringify(item)">
      <div class="header">
        <cite class="small">{{ item.time }}</cite>
      </div>
      <div class="body" style="text-align: left;">{{ item.info }} </div>
    </div>
  </div>
  <button v-if="state.loginLog.length" class="btn btn-sm btn-primary btn--loginlog " type="button"
    @click="toggleLoginlog">loginLog</button>


</template>

<style lang="scss" scoped>
.cards {
  position: relative;

  .card {
    overflow: hidden;
  }
}
::v-deep video{
  object-fit: contain !important;
}

//#local {
//  width: 200px;
//  height: 150px;
//  position: absolute;
//  left: 0;
//  top: 0;
//  padding: 2em;
//}

.cards {
  display: grid;
  // grid-auto-columns: 400px;
  // grid-template-columns: (3, 400px);
  grid-template-columns: repeat(auto-fit, minmax(400px, 400px));
  grid-auto-rows: 300px;
  gap: 10px;

  /* 这是网格项之间的间距，可选 */
  .card {
    width: 400px;
    height: 300px;
    position: relative;

    .video {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  .btns {
    position: absolute;
    right: 20px;
    bottom: 20px;
    display: flex;
    gap: 20px;
    z-index: 2;


    .btn {
      border-radius: 26px;
      position: relative;
      width: 26px;
      height: 26px;

      background-size: 80%;
      background-position: center;
      background-repeat: no-repeat;
      background-blend-mode: multiply;

      background-color: white;
    }
  }

  .peer-uid {
    color: black;
    position: absolute;
    top: 0;
    left: 8px;
    letter-spacing: 1px;
    text-rendering: geometricPrecision;
    -webkit-text-stroke: 1px;
    /* 白色描边 */
    text-shadow: 0 0 1px white, 0 0 2px white, 0 0 3px white;
  }
}

.col {
  label {
    float: left;
    padding-left: 12px;
  }

  .btns {
    display: flex;
    align-items: end;
  }
}

.msgs {
  position: fixed;
  bottom: 50px;
  right: 30px;
  width: 260px;
  z-index: 9;
  padding: 12px;

  &.loginlog {
    left: 30px;
  }

  overflow-y: auto;
  max-height: 60vh;



  .msg {
    margin-bottom: 8px;

    .header {
      opacity: .7;
      display: flex;
      justify-content: space-between;
    }
  }
}

.btn--close {
  position: absolute;
  bottom: 10px;
  right: 30px;
}

.btn--loginlog {
  position: absolute;
  bottom: 10px;
  left: 30px;
}
</style>
